import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
// @ts-ignore
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./App.css";
import "./file-block";
import { firestore } from "./firebaseConfig.js";
import { addDoc, collection } from "firebase/firestore";

// @ts-ignore
registerCoreBlocks();

function App() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId={1}
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome",
              attributes: {
                label: "Welcome to Fenus",
                description: "This is our Software Developer Intern screening survey.",
                attachment: {
                  type: "image",
                  url: "https://fenus.io/static/fenus_logo-01fce42957dbe474cea6448e9fd973f8.png",
                },
              },
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "Please enter your full name.",
              },
            },
            {
              name: "email",
              id: "email",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "Please provide your email.",
              },
            },
            {
              name: "website",
              id: "linkedin",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "Please provide the link to your LinkedIn profile.",
              },
            },
            {
              name: "short-text",
              id: "country",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "Which country are you located in.",
              },
            },
            {
              name: "long-text",
              id: "experience",
              attributes: {
                classnames: "first-block",
                required: true,
                label:
                  "Please briefly describe your experience and skills as a software developer.",
              },
            },
            {
              name: "long-text",
              id: "technology",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "What areas of technology excite you and you want to learn more about?",
              },
            },
            {
              name: "file-upload",
              id: "upload",
              attributes: {
                label: "Please upload a PDF copy of your CV",
                required: true,
                allowedFileExtensions: "pdf",
                maxFileSize: 2,
              },
            },
            {
              name: "statement",
              id: "finish",
              attributes: {
                label: "Thank you.",
                description: "Press submit to send your answers.",
                attachment: {
                  type: "image",
                  url: "https://fenus.io/static/fenus_logo-01fce42957dbe474cea6448e9fd973f8.png",
                },
              },
            },
          ],
        }}
        onSubmit={async (data: any, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
          // console.log(data);
          const colRef = collection(firestore, "dev-intern-2023-08-1");
          await addDoc(colRef, {
            name: data.answers.name.value,
            country: data.answers.country.value,
            email: data.answers.email.value,
            experience: data.answers.experience.value,
            linkedin: data.answers.linkedin.value,
            technology: data.answers.technology.value,
            file: data.answers.upload.value[0].hash,
          });

          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
        applyLogic={false}
      />
    </div>
  );
}

export default App;
